<template>
   <div class="card mb-0" ref="nota" :index="index">
      <div class="card-img text-center overflow-hidden cursor-pointer" @click="visualizar">
         <img src="@/assets/images/notas/aceita.png" v-if="nota.status == 'Aceita'">
         <img src="@/assets/images/notas/recusada.png" v-else-if="nota.status == 'Recusada'">
         <img src="@/assets/images/notas/pendente.png" v-else-if="nota.status == 'Manifestada'">
         <img src="@/assets/images/notas/nfe.png" v-else>
      </div>
      <div class="card-body text-center p-2">
         <h5 class="align-text-center">
            <span class="limitador-2" v-if="nota.nomeRespTecnico != 'Edipo Oliveira Sapanhos'">{{ nota.nomeEmitente != null ? String(nota.nomeEmitente).toUpperCase() : String(nota.razaoSocialEmitente).toUpperCase() }}</span>
            <span class="limitador-2" v-else>{{ nota.nomeDestinatario != null ? String(nota.nomeDestinatario).toUpperCase() : String(nota.razaoSocialDestinatario).toUpperCase() }}</span>
         </h5>
         <p class="mb-2 text-secondary d-none d-lg-block"><span class="limitador-1 font-8">{{ nota.chave }}</span></p>
         <p class="limitador-1 weight-500 mb-2">
            <small>R$ </small><span class="font-15">{{ parseFloat(nota.valor).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</span>
         </p>
         <p class="mb-0 d-none d-sm-block">
            <span class="limitador-1">
               <span>
                  <i class="far fa-calendar color-theme font-10 me-1"></i>
                  <span v-if="nota.dataNota != null"> {{ nota.dataNota.dayOfMonth.toString().padStart(2, '0') +'/'+ nota.dataNota.monthValue.toString().padStart(2, '0') }}</span>
                  <span v-if="nota.dataNota != null && nota.dataRecebimento == null">/{{ nota.dataNota.year }} </span>
                  <span v-if="nota.dataNota != null"> {{ nota.dataNota.hour.toString().padStart(2, '0') +':'+ nota.dataNota.minute.toString().padStart(2, '0') }}</span>
               </span>
               <span v-if="nota.dataRecebimento != null" class="d-none d-lg-inline">
                  <small class="mx-1"> | </small>
                  <i class="far fa-dolly font-10 color-theme me-1"></i>
                  <span>{{ nota.dataRecebimento.dayOfMonth.toString().padStart(2, '0') +'/'+ nota.dataRecebimento.monthValue.toString().padStart(2, '0') }}</span>
                  <span> {{ nota.dataRecebimento.hour.toString().padStart(2, '0') +':'+ nota.dataRecebimento.minute.toString().padStart(2, '0') }}</span>
               </span>
            </span>
         </p>
         <p class="mb-0 d-none d-lg-block">
            <span class="limitador-1">
               <i class="far fa-store color-theme font-10 me-1"></i><span> {{ nota.nomeLoja == null ? 'Sem loja' : nota.nomeLoja }}</span>
               <span v-if="nota.numeroLoja"><small class="mx-1"> | </small><span><i class="far fa-tag font-9 me-1"></i> {{ nota.numeroLoja }}</span></span>
            </span>
         </p>
         <p class="mb-1">
            <span class="limitador-1">
               <i v-if="nota.status == 'Aceita'" class="fas fa-check text-green font-11 me-1"></i>
               <i v-else-if="nota.status == 'Recusada'" class="far fa-times text-red font-11 me-1"></i>
               <i v-else-if="nota.status == 'Manifestada'" class="far fa-sticky-note text-yellow font-11 me-1"></i>
               <i v-else-if="nota.status == 'Recebimento - Entregue'" class="far fa-dolly text-yellow font-11 me-1"></i>
               <i v-else-if="nota.status == 'Recebimento - Validado'" class="far fa-box-check text-green font-11 me-1"></i>
               <span> {{ nota.status }}</span>
               <span v-if="nota.dataFinalizado != null" class="d-none d-lg-inline">
                  <small class="mx-1"> | </small>
                  <span>{{ nota.dataFinalizado.dayOfMonth.toString().padStart(2, '0') +'/'+ nota.dataFinalizado.monthValue.toString().padStart(2, '0') }} </span>
                  <span>{{ nota.dataFinalizado.hour.toString().padStart(2, '0') +':'+ nota.dataFinalizado.minute.toString().padStart(2, '0') }}</span>
               </span>
            </span>
         </p>
         <div class="row justify-content-center">
            <div class="col">
               <button class="btn btn-white invert w-100" @click="visualizar"><span class="limitador-1"><i class="fal fa-plus font-11 me-1"></i> Ver mais</span></button>
            </div>
            <div class="col" v-if="showCancelar && dadosUsuario.usuarioPermissoes.includes('Fiscal | Manifestar notas') && nota.nomeRespTecnico == 'Edipo Oliveira Sapanhos' && nota.status != 'Recusada' && dataLimiteCancelar > new Date()">
               <button class="btn btn-white invert w-100" @click="cancelar">
                  <span class="limitador-1"><i class="fal fa-ban font-11 me-1"></i> Cancelar</span>
               </button>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import Swal from 'sweetalert2'
import { mapState } from 'vuex'

export default {
   name: 'Nota',
   props: ['nota', 'index', 'showCancelar'],
   data: function() {
		return {
			dataLimiteCancelar: new Date()
		}
	},
   computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
         urlRest: state => state.urlRest
      })
   },
   methods: {
      visualizar : function () {
         this.$emit('visualizar', this.nota)
      },
      cancelar : function () {
         Swal.fire({
            icon: 'warning',
            title: 'Tem certeza?',
            text: 'A nota selecionada será cancelada!',
            showCancelButton: true,
				input: 'textarea',
				inputPlaceholder: 'Informe uma justificativa. (Mínimo de 15 caracteres)',
            confirmButtonText: 'Confirmar',
            cancelButtonText: `Cancelar`,
            inputValidator: (value) => {
               if (String(value).trim().length < 15) {
                  return 'Justificativa inválida!'
               }
            }
         }).then((result) => {
            if (result.isConfirmed) {
               this.$store.dispatch('alternarTelaCarregamento', true)

               this.$axios({
                  method: 'post',
                  url: this.urlRest +'fiscal/cancelarNota',
                  headers: {'Content-Type': 'application/json'},
                  data: {'chave': this.nota.chave, 'observacao': String(result.value).trim()}

               }).then(() => {
						this.$refs.nota.style.display = 'none'

                  Swal.fire({
                     icon: 'success',
                     title: 'Nota cancelada!'
                  });
               }).catch((error) => {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        this.$store.dispatch('deslogar')

                        this.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else {
                        this.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        });
                     }
                  }
               }).finally(() => {
                  this.$store.dispatch('alternarTelaCarregamento', false)
               });
            }
         })
      }
   },
   created() {
      let dataNota = this.nota.dataNota.monthValue +'/'+ this.nota.dataNota.dayOfMonth +'/'+ this.nota.dataNota.year +' '+ this.nota.dataNota.hour +':'+ this.nota.dataNota.minute;
      this.dataLimiteCancelar = new Date(new Date(dataNota).setDate(new Date(dataNota).getDate() + 1))
   }
}

</script>

<style scoped>

.col {
   max-width: 100px;
}

h5 {
   font-size: 14px;
   margin-bottom: 8px;
   font-weight: 500;
   line-height: 20px;
   height: 40px;
}

.card[index] img {
	aspect-ratio: 1/1;
	object-fit: cover;
   max-width: 100px;
   margin: 10px 0;
   transition: all .3s ease-out 0s;
}

.card[index]:hover img {
   transform: scale(1.1) rotate(1deg);
}

p span {
   font-size: 12px;
   text-transform: capitalize;
}

button {
   margin-top: 10px;
   transition: all 0.5s;
   padding: 7px 6px 5px;
   font-size: 12px;
}

button:hover {
   background-color: var(--color-theme);
   border-color: var(--color-theme);
   color: #fff;
   border-radius: 3px;
}

</style>